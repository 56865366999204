@import 'src/styles/vars';

.row {
  border-left: 0;
  &_active {
    background: map-get($colorsMap, 'ElementsHoverActive') !important;
    td {
      box-shadow: inset 0 1px 0 map-get($colorsMap, 'ElementsBlue'),
        inset 0 -1px 0 map-get($colorsMap, 'ElementsBlue');
    }
  }
  &:hover {
    background:  map-get($colorsMap, 'ElementsHoverActive') !important;
  }
}
