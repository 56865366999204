@import 'src/styles/vars';

.avatar {
  background-color: map-get($colorsMap, 'ElementsGrey');
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}
