@import 'src/styles/vars';

.viewButton {
  padding: 8px;
  color: map-get($colorsMap, 'ElementsGrey');

  &:hover {
    background-color: map-get($colorsMap, 'ElementsHover');
    color: map-get($colorsMap, 'ElementsGrey');
    border-radius: 10px;
  }
}

.selected {
  color: map-get($colorsMap, 'ElementsDark');
  &:hover {
    color: map-get($colorsMap, 'ElementsDark');
  }
}

.functionContent {
  height: calc(100vh - 405px);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    width: 8px;
    background-color: map-get($colorsMap, 'ElementsField');
  }

  &::-webkit-scrollbar-thumb {
    background-color: map-get($colorsMap, 'ElementsScroll');
    border-radius: 10px;
  }
}
