@import '../../../../../../../styles/vars';

.card {
  width: 240px;
  height: 150px;

  padding: 20px;

  border-radius: 10px;

  background-color: map-get($colorsMap, 'ElementsField');
}

.placeholder {
  width: 24px;
  height: 24px;

  border-radius: 100%;
}

.wizard {
  background-color: #1aaec0;
}

.full {
  background-color: #e57d14;
}

.selected {
  background-color: map-get($colorsMap, 'ElementsBlue');
}
