@import 'src/styles/vars';

.container {
  height: calc(100vh - 138px);

  padding: 30px 0 0 0;

  margin-left: 20px;

  border-radius: 30px 30px 0 0;
  background-color: map-get($colorsMap, 'BGWhite');
}

.search {
  margin: 0 0 0 30px;
}

.selected {
  color: map-get($colorsMap, 'ElementsDark');
}
