@import 'src/styles/vars';

.scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    width: 8px;
    background-color: map-get($colorsMap, 'ElementsField');
  }

  &::-webkit-scrollbar-thumb {
    background-color: map-get($colorsMap, 'ElementsScroll');
    border-radius: 10px;
  }
}
