@import 'src/styles/vars';

.container {
  padding: 16px 60px 15px 60px;
  border-bottom: 1px solid map-get($colorsMap, 'ElementsBorder');
  background: map-get($colorsMap, 'BGWhite');
}

.userCard {
  gap: 10px;
}
.wrapper {
  background-color: map-get($colorsMap, 'BGBlueGrey');
}

.content {
  min-width: 85%;
  max-width: calc(100% - 64px);
}

.dropDown {
  width: 250px;
  background-color: map-get($colorsMap, 'BGWhite');
  border-radius: 0 0 0 20px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.dropDownItem {
  cursor: pointer;

  &:hover {
    color: map-get($colorsMap, 'ElementsBlue');
  }
}
