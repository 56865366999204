@import 'src/styles/vars';

.container {
  padding-left: 30px;
  padding-right: 30px;

  &:hover {
    padding-left: 28px;
    background-color: map-get($colorsMap, 'ElementsHover');
    border-left: 2px solid map-get($colorsMap, 'ElementsBlue');

    .name {
      color: map-get($colorsMap, 'TextBlue');
    }

    .icon {
      filter: invert(46%) sepia(56%) saturate(5748%) hue-rotate(208deg) brightness(98%)
        contrast(90%);
    }
  }
}

.icon {
  filter: invert(62%) sepia(11%) saturate(737%) hue-rotate(186deg) brightness(89%) contrast(94%);
}

.button {
  padding: 0;
}

.pinnedIcon {
  color: map-get($colorsMap, 'ElementsBlue');

  &:hover {
    color: map-get($colorsMap, 'ElementsGrey');
  }

  &__disabled {
    color: map-get($colorsMap, 'ElementsGrey');
    pointer-events: none;
  }
}

.unPinnedIcon {
  color: map-get($colorsMap, 'ElementsGrey');

  &:hover {
    color: map-get($colorsMap, 'ElementsBlue');
  }

  &__disabled {
    color: map-get($colorsMap, 'ElementsGrey');
    pointer-events: none;
  }
}
