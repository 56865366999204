@import "src/styles/vars";

.item {
  cursor: pointer;
  &_active {
    background: map-get($colorsMap, 'ElementsStrokeLight') !important;
  }
  &:hover {
    background: map-get($colorsMap, 'ElementsStrokeLight') !important;
  }
}