@import 'src/styles/vars';
@import 'bootstrap/scss/mixins/breakpoints';

@include media-breakpoint-up(xs) {
  .formContainer {
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .formContainer {
    width: 50%;
  }
}
