@import 'src/styles/vars';

.container {
  margin: 20px;
  gap: 20px;
}

.wrapper {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
