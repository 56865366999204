@import '/src/styles/vars';

.personsContainer {
  width: calc(100% - 30px);
  margin-right: 30px;
  overflow-y: auto;

  height: calc(100vh - 290px);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 30px;

    width: 8px;
    background-color: map-get($colorsMap, 'ElementsField');
  }

  &::-webkit-scrollbar-thumb {
    background-color: map-get($colorsMap, 'ElementsScroll');
    border-radius: 10px;
  }
}

.placeHolder {
  padding: 90px 0;

  color: map-get($colorsMap, 'ElementsGrey');
}

.buttonContainer {
  background-color: map-get($colorsMap, 'BGWhite');

  position: sticky;
  z-index: 20;
  bottom: 0;

  padding: 10px 0;

  .functionButton {
    width: calc(100% - 50px);
    margin: 0 20px 0 30px;
  }
}
