.node-motion-appear {
    overflow: hidden;
  }

  
  .node-motion-appear-active {
    transition: height 0.3s ease-out;
  }
  
  .node-motion-leave {
    overflow: hidden;
  }
  
  .node-motion-leave-active {
    transition: height 0.3s ease-out;
   
  }