@import '@/styles/vars';

.modalContainer {
  background-color: map-get($colorsMap, 'Overlay');
}

.closeButtonContainer {
  background-color: #f8f9fe4d;
  border-radius: 10px;
}

.closeButton {
  padding: 8px;
  color: map-get($colorsMap, 'TextWhite');

  &:hover {
    color: map-get($colorsMap, 'ElementsPressed');
  }
}
