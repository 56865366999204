@import 'src/styles/vars';

.skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */

  .row {
    width: 100%;
    height: 24px;
    border-radius: 0.25rem;
    display: inline-flex;
    background-color: map-get($colorsMap, 'ElementsField');
    position: relative;
    overflow: hidden;
    z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */

    .title {
      width: 100% !important;
      height: 24px;
    }

    &:nth-child(2) {
      width: 85%;
    }

    &:nth-child(3) {
      width: 80%;
    }

    &:nth-child(4) {
      width: 90%;
    }

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background-repeat: no-repeat;
      background-image: linear-gradient(
        90deg,
        map-get($colorsMap, 'ElementsField'),
        map-get($colorsMap, 'ElementsGrey'),
        map-get($colorsMap, 'ElementsField')
      );
      transform: translateX(-100%);
      animation-name: loading-skeleton;
      animation-direction: normal;
      animation-duration: 1.5s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;

      @keyframes loading-skeleton {
        100% {
          transform: translateX(100%);
        }
      }
    }
  }
}
