@import 'src/styles/vars';

.container {
  padding-left: 30px;
  margin-right: 20px;
  border-left: 2px solid map-get($colorsMap, 'BGWhite');

  &:hover {
    background-color: map-get($colorsMap, 'ElementsHover');
    border-left: 2px solid map-get($colorsMap, 'ElementsBlue');
  }
}

.checked {
  background-color: map-get($colorsMap, 'ElementsHover');
  border-left: 2px solid map-get($colorsMap, 'ElementsBlue');
}

.arrowButton {
  display: flex;
  align-items: center;
  justify-content: start;

  width: 20px;
  height: 20px;

  color: map-get($colorsMap, 'TextPrimary');

  cursor: pointer;
}