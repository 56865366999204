.container {
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(22, 31, 50, 0.2);
  gap: 15px;
  width: 300px;
  max-width: 95vw;
  padding: 16px 14px;
  border-radius: 6px;
}

.cross {
  margin-top: 12px;
  margin-right: 14px;
}
