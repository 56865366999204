@import 'src/styles/vars';

.container {
  padding: 20px;
  background-color: map-get($colorsMap, 'ElementsPressed');

  border-radius: 10px;

  height: 138px;

  &:hover {
    background-color: map-get($colorsMap, 'ElementsBlue');

    .name {
      color: map-get($colorsMap, 'TextWhite');
    }

    .icon {
      filter: invert(100%) sepia(8%) saturate(10%) hue-rotate(209deg) brightness(101%);
    }

    .dragIcon {
      color: map-get($colorsMap, 'BGWhite-50');
    }
  }

  &:hover .dragIconDragging {
    color: map-get($colorsMap, 'BGWhite');
  }

  .icon {
    filter: invert(61%) sepia(93%) saturate(5921%) hue-rotate(209deg) brightness(100%) contrast(88%);
  }
}

.isDragging {
  outline: 2px dashed map-get($colorsMap, 'ElementsBlue');
  outline-offset: 2px;

  background-color: map-get($colorsMap, 'ElementsBlue');

  .name {
    color: map-get($colorsMap, 'TextWhite');
  }

  .icon {
    filter: invert(100%) sepia(8%) saturate(10%) hue-rotate(209deg) brightness(101%);
  }
}

.dragIcon {
  color: map-get($colorsMap, 'ElementsPressed');
}
