@import 'src/styles/vars';

.filterButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  cursor: pointer;
  color: map-get($colorsMap, 'TextSecondary');
}

.searchInput {
  width: calc(100% - 30px);

  padding: 10px 16px 0 16px;
  margin: 30px 0 0 14px;
}

.title {
  margin-left: 30px;
}

.filterContainer {
  padding: 30px 12px 12px 30px;

  position: sticky;
  top: 0;
  z-index: 20;

  background-color: map-get($colorsMap, 'BGWhite');
}

.departmentContainer {
  width: 100%;
  overflow-y: auto;

  height: calc(100vh - 330px);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 30px;

    width: 8px;
    background-color: map-get($colorsMap, 'ElementsField');
  }

  &::-webkit-scrollbar-thumb {
    background-color: map-get($colorsMap, 'ElementsScroll');
    border-radius: 10px;
  }
}
