@import 'src/styles/vars';

.timeline__divider {
  left: 0px;
  padding-left: 1px;
  padding-right: 1px;
  top: 0px;
  background: repeating-linear-gradient(
    180deg,
    map-get($colorsMap, 'ElementsBlue') 0 10px,
    #0000 0 15px
  );
  align-self: flex-start;
  height: 100%;
  left: 2.5px;
  position: absolute;
  top: 28px;
  height: calc(100% - 28px);
}

.timeline__vertical {
  position: absolute;
  top: 0px;
  left: 0;
  height: 100%;
}

.timelapse__head {
  height: 24px;
  width: 0.5rem;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline__icon {
  background: map-get($colorsMap, 'ElementsBlue');
  border-color: transparent;
  border-width: 4px;
  border-radius: 9999px;
  width: 0.5rem;
  height: 0.5rem;
}