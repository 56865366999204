@import 'src/styles/vars';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.sticky {
  z-index: 20;
  position: sticky;
  top: 0;
  border-top: none;
  border-bottom: none;
  box-shadow: inset 0 1px 0 map-get($colorsMap, 'ElementsBorderLight'),
    inset 0 -1px 0 map-get($colorsMap, 'ElementsBorderLight');
  padding: 1px 0;
  background: map-get($colorsMap, 'BGWhite');
}

.sticky::before {
  content: '';
  display: block;
  height: 100%;
  width: 3px;
  position: absolute;
  left: -2px;
  background: map-get($colorsMap, 'BGWhite');
}

.row {
  background: map-get($colorsMap, 'BGWhite');
  cursor: pointer;
  border-left: 2px solid transparent;
  border-bottom: 1px solid map-get($colorsMap, 'ElementsScroll');
  &_active {
    border-left: 2px solid map-get($colorsMap, 'ElementsBlue');
    background: map-get($colorsMap, 'ElementsStrokeLight') !important;
  }
  &:hover {
    background: map-get($colorsMap, 'ElementsStrokeLight') !important;
  }
}
