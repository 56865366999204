@import 'src/styles/vars';

.container {
  height: calc(100vh - 162px);

  padding: 30px;

  border-radius: 20px 20px 0 0;
  background-color: map-get($colorsMap, 'BGWhite');
}
