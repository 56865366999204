@import 'src/styles/vars';

.selectedPaginationPage {
  width: 26px;
  height: 24px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #b5c5e4;
  background-color: #f2f4f9;

  user-select: none;

  font-size: 12px;

  &Inactive {
    border-color: #b5c5e4;
  }

  &Active {
    color: map-get($colorsMap, 'TextWhite');
    border-color: transparent;
    background-color: #3575ef;
  }
}

.pagesList {
  gap: 10px;
}
