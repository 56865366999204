@import 'src/styles/vars';
.activeIcon {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(143deg) brightness(106%) contrast(101%);
}

.newTabButtonContainer {
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
  position: sticky;
  right: -1px;
  background-color: map-get($colorsMap, 'BGWhite');
}
