@import 'src/styles/vars';

.container {
  width: 24px;
  height: 24px;

  input {
    appearance: none;
  }

  .iconContainer {
    color: map-get($colorsMap, 'ElementsGrey');

    &:hover {
      color: map-get($colorsMap, 'ElementsBlue');
    }
  }

  .iconActive {
    color: map-get($colorsMap, 'ElementsBlue');

    &:hover {
      color: map-get($colorsMap, 'ElementsGrey');
    }
  }
  
  .iconDisabled {
    color: map-get($colorsMap, 'ElementsGrey');
  }
}
