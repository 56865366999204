@import 'src/styles/vars';

.container {
  padding: 0;
}

.filterButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  color: map-get($colorsMap, 'TextSecondary');

  cursor: pointer;
}

.searchInput {
  width: calc(100% - 30px);

  padding: 10px 16px 0 16px;

  margin: 30px 0 0 14px;
}

.checkAll {
  margin-left: 30px;
}

.filterContainer {
  padding-right: 30px;
  padding-top: 30px;

  background-color: #fff;

  padding-bottom: 19px;
  z-index: 20;
  position: sticky;

  top: 0;
}

.personsContainer {
  width: 100%;
  margin-right: 30px;
  overflow-y: auto;

  height: calc(100vh - 330px);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 30px;

    width: 8px;
    background-color: map-get($colorsMap, 'ElementsField');
  }

  &::-webkit-scrollbar-thumb {
    background-color: map-get($colorsMap, 'ElementsScroll');
    border-radius: 10px;
  }
}
