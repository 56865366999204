@tailwind base;
@tailwind components;

@import 'vars';
@import 'inter.font';

@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  width: 8px;
  height: 8px;
  background-color: map-get($colorsMap, 'ElementsField');
}

::-webkit-scrollbar-thumb {
  background-color: map-get($colorsMap, 'ElementsScroll');
  border-radius: 10px;
}

*,
::before,
::after {
  border-color: map-get($colorsMap, 'ElementsBorderLight');
}
