$colorsMap: (
  'TextPrimary': #0d1627,
  'TextSecondary': #6d7481,
  'TextDisabled': #8591a7,
  'TextBlue': #3575ef,
  'TextWhite': #fff,
  'TextError': #dc1717,
  'ElementsBlue': #3575ef,
  'ElementsBlueHover': #518af9,
  'ElementsBluePressed': #1a63f0,
  'ElementsBorder': #b5c5e4,
  'ElementsBorderLight': #d5dbe9,
  'ElementsScroll': #c3cbdf,
  'ElementsStrokeLight': #E5E8F2,
  'ElementsDark': #0e2045,
  'ElementsGrey': #838faf,
  'ElementsHover': #f2f4f9,
  'ElementsHoverLight': #f8F9fe,
  'ElementsHoverActive': #edf3ff,
  'ElementsPressed': #e8ebf2,
  'ElementsField': #f1f3f8,
  'ElementsWhite': #fff,
  'BGBlueGrey': #f1f3f8,
  'BGWhite': #fff,
  'BGWhite-50': #ffffff80,
  'Transparent': 'transparent',
  'Overlay': #060a2e80,
  'black': #000,
);

$grid-breakpoints: (
  xs: 0,
  sm: 360px,
  md: 767px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1400px,
);
