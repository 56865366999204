@import 'src/styles/vars';

.dropDownContent {
  width: 350px;
  padding: 2px;

  background-color: map-get($colorsMap, 'BGWhite');

  border-radius: 6px;

  .dropDownItem {
    width: 100%;

    padding: 6px 12px;
    border-radius: 6px;

    cursor: pointer;

    &:hover {
      background-color: map-get($colorsMap, 'ElementsHover');
    }
  }
}
