@import 'src/styles/vars';

.container {
}

.button {
  padding: 0;
  color: white;

  &:hover {
    color: map-get($colorsMap, 'ElementsGrey');
  }
}

.wizard {
  background-color: #e57d14;
}

.full {
  background-color: #1aaec0;
}
